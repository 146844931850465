import * as React from 'react'
import { compose, get, map } from 'lodash/fp'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import Layout from 'src/components/Layouts'
import { localize } from 'src/components/Localize'
import Container from 'src/components/Container'
import Headings from '@/components/Headings'
import NavLink from '@/components/NavLink'
import { UnsubscribeIllustration } from '@/components/UnsubscribeIllustration'

const NotFound = ({ pageContext, data }) => {
  const { locale } = pageContext

  const menuItems = compose(map('node'), get('allSanityMenus.edges'))(data)
  const siteGlobal = compose(map('node._rawContent'), get('allSanitySiteGlobal.edges'))(data)

  const navMenuItems = menuItems.map((menuItem) => ({
    ...menuItem,
    items: menuItem.items.map((item) => ({
      ...item,
      link: get('link.content.main.slug.current', item),
    })),
  }))

  return (
    <Layout navMenuItems={navMenuItems} siteGlobal={siteGlobal[0]} locale={locale}>
      <Wrapper>
        <Container>
          {UnsubscribeIllustration}
          <Headings.h2
            css={css`
              margin-top: 3rem;
              color: #737694;
            `}
          >
            {locale === 'tr' ? 'Aradığınız sayfa bulunamadı!' : 'Sidan kunde inte hittas!'}
          </Headings.h2>
          <Headings.h4
            css={css`
              color: #3b3c4a;
              max-width: 700px;
              text-align: center;
              margin: 1rem auto auto;
              font-weight: 500;
            `}
          >
            {locale === 'tr' ? (
              <>
                Yayından kaldırılmış veya geçici bir süreliğine indirilmiştir. Ana sayfaya dönmek
                için
                <NavLink
                  to="/"
                  css={css`
                    color: #688cf3;
                  `}
                >
                  buraya tıklayınız
                </NavLink>
              </>
            ) : (
              <>
                Den kan ha blivit borttagen, flyttad eller vara tillfälligt nere.{' '}
                <NavLink
                  to="/"
                  css={css`
                    color: #688cf3;
                  `}
                >
                  Klicka här
                </NavLink>{' '}
                för att återvända till startsidan.
              </>
            )}
          </Headings.h4>
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default localize(NotFound)

const Wrapper = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f3ff;
  text-align: center;
`
export const query = graphql`
  query NotFoundTemplateQuery {
    allSanitySiteGlobal {
      edges {
        node {
          _rawContent
        }
      }
    }
    allSanityMenus {
      edges {
        node {
          menuLocation {
            mainMenu
            footerMenu
          }
          items {
            ... on SanityInternalLink {
              title {
                no
                tr
                _type
              }
              link {
                content {
                  main {
                    slug {
                      _type
                      tr {
                        current
                        _type
                      }
                      no {
                        current
                        _type
                      }
                    }
                  }
                }
              }
            }
          }
          title {
            no
            tr
            _type
          }
          slug {
            _type
            tr {
              current
              _type
            }
            no {
              current
              _type
            }
          }
        }
      }
    }
  }
`
